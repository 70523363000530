import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import jobBgImg from "../../images/jobBg.jpg"
import Banner from "../../components/Banner/BannerMain"
import SectionTitle from "../../components/SectionTitle"
import CoreCareer from "../../components/CareerList"


function Careers() {
  return (
    <Layout>
      <Seo title="Careers" />
      <section
        id="home"
        className="header bg-blue-100 relative  items-center flex w-full"
        >
        <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
            <Banner
            title="Take your career to the next level."
            titleTag="Be a part of revolution"
            subTitle=" We offer Full-Time, Part-time, Remote and internship"
            subTag="Careers designed to empower you!"
            img={jobBgImg}
            />
        </div>
        </section>

        <section className="relative body-font w-full pt-10 pb-3 md:pb-20">
            <div className="container px-4 max-w-6xl mx-auto items-center flex flex-wrap">
            <SectionTitle
                titleHead="Current Openings"
                title="We have a list of openings"
                spanTag="Apply Now"
                customClass="text-center md:w-full xl:w-full"
            />
            <CoreCareer />
            </div>
        </section>
    </Layout>
  )
}

export default Careers
