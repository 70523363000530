import { navigate } from "gatsby"
import React from "react"

const CareerItem = (
    {
        id,
        title, 
        jobType, 
        jobLocation, 
        jobExpiry, 
    }:{ 
        id: number,
        title:string, 
        jobType:string, 
        jobLocation:string, 
        jobExpiry:string,
        }
    ) => {
  return (
    <>
            <div className="mb-5 lg:w-1/2 p-3">
                <div className="h-full border border-themeBorder overflow-hidden py-5 px-3  rounded-lg  ease-linear transition-all duration-200   hover:shadow-lg cursor-pointer" data-aos="fade-up">
                    <div className="lg:flex lg:justify-between lg:items-center">
                        <div className="">
                            <h4 className="text-textColor text-lg title-font font-semibold">{title}</h4>
                            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap">
                                <div className="mt-1 flex items-center text-gray-600 text-sm mr-2">
                                    <svg className="mr-1 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
                                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                                    </svg>
                                    {jobType}
                                </div>
                                <div className="mt-1 flex items-center text-gray-600 text-sm mr-2">
                                    <svg className="mr-1 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                    </svg>
                                    {jobLocation}
                                </div>
                            </div>
                            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap">
                                <div className="mt-1 flex items-center text-sm text-gray-600 mr-2 text-blue-400">
                                    <svg className="mr-1 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                    </svg>
                                    <span className="text-gray-600 mr-1">Application ends :</span> {jobExpiry}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 lg:mt-0 lg:ml-4">
                            <button type="button" className="inline-flex items-center px-4 py-2  rounded text-sm font-medium text-white bg-emerald-400 hover:bg-emerald-600 focus:outline-none"
                                onClick={() =>
                                    navigate(
                                        "./career-detail/" + id
                                    )
                                }
                            >
                                View Details
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        
    </>
  )
}

export default CareerItem
