import React from "react"
import { jobDetailData } from "../../data/careers"
import CareerItem from "./CareerItem"

const CoreCareer = () => {
  return (
    <>
        <div className="flex flex-wrap w-full mb-5 justify-center ">
            {jobDetailData.map(job => (
            <CareerItem
                key={job.id}
                id={job.id}
                title={job.jobTitle}
                jobType={job.jobType}
                jobLocation={job.jobLocation}
                jobExpiry={job.jobExpiry}
            />
            ))}
        </div>
    </>
  )
}

export default CoreCareer
